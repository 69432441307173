import _ from 'lodash'
import { IPageProvider, IPageReflector, PageProviderSymbol } from 'feature-pages'
import { IPopupsLinkUtilsAPI, PopupsLinkUtilsAPISymbol } from 'feature-popups'
import { multi, named, optional, withDependencies } from '@wix/thunderbolt-ioc'
import {
	CompEventsRegistrarSym,
	ComponentsStylesOverridesSymbol,
	IAppWillLoadPageHandler,
	ICompEventsRegistrar,
	IComponentsStylesOverrides,
	ILanguage,
	ILogger,
	IPropsStore,
	LanguageSymbol,
	LoggerSymbol,
	PlatformEnvDataProvider,
	PlatformEvnDataProviderSymbol,
	PlatformSiteConfig,
	Props,
	PropsMap,
	SdkHandlersProvider,
	SiteFeatureConfigSymbol,
	ViewerModel,
	ViewerModelSym,
	WixBiSession,
	WixBiSessionSymbol,
	WixCodeSdkHandlersProviderSym
} from '@wix/thunderbolt-symbols'
import { PlatformInitializer, ViewerAPI } from './types'
import { name, PlatformInitializerSym } from './symbols'
import { DebugApis, TbDebugSymbol } from 'feature-debug'
import { IMultilingualLinkUtilsAPI, MultilingualLinkUtilsAPISymbol } from 'feature-multilingual'
import { createBootstrapData } from './viewer/createBootstrapData'

export const Platform = withDependencies(
	[
		PlatformInitializerSym,
		named(SiteFeatureConfigSymbol, name),
		ViewerModelSym,
		Props,
		LoggerSymbol,
		WixBiSessionSymbol,
		CompEventsRegistrarSym,
		LanguageSymbol,
		optional(PopupsLinkUtilsAPISymbol),
		optional(MultilingualLinkUtilsAPISymbol),
		PageProviderSymbol,
		multi(WixCodeSdkHandlersProviderSym),
		multi(PlatformEvnDataProviderSymbol),
		ComponentsStylesOverridesSymbol,
		optional(TbDebugSymbol)
	],
	(
		platformRunnerContext: PlatformInitializer,
		platformSiteConfig: PlatformSiteConfig,
		viewerModel: ViewerModel,
		propsStore: IPropsStore,
		logger: ILogger,
		wixBiSession: WixBiSession,
		compEventsRegistrar: ICompEventsRegistrar,
		language: ILanguage,
		popupsLinkUtilsAPI: IPopupsLinkUtilsAPI,
		multilingualLinkUtilsAPI: IMultilingualLinkUtilsAPI,
		pageProvider: IPageProvider,
		siteHandlersProviders: Array<SdkHandlersProvider>,
		platformEnvDataProviders: Array<PlatformEnvDataProvider>,
		componentsStylesOverrides: IComponentsStylesOverrides,
		debugApi?: DebugApis
	): IAppWillLoadPageHandler => {
		const siteHandlers = siteHandlersProviders.map((siteHandlerProvider) => siteHandlerProvider.getSdkHandlers())
		function getHandlers(page: IPageReflector) {
			const pageHandlersProviders = page.getAllImplementersOf<SdkHandlersProvider>(WixCodeSdkHandlersProviderSym)
			const pageHandlers = pageHandlersProviders.map((pageHandlerProvider) => pageHandlerProvider.getSdkHandlers())
			return Object.assign({}, ...pageHandlers, ...siteHandlers)
		}

		function getPlatformEnvData() {
			return Object.assign({}, ...platformEnvDataProviders.map((envApiProvider) => envApiProvider.platformEnvData))
		}

		const {
			bootstrapData: siteConfigBootstrapData,
			landingPageId,
			isChancePlatformOnLandingPage,
			debug: { disablePlatform }
		} = platformSiteConfig

		siteConfigBootstrapData.platformServicesAPIData.link.popupPages = popupsLinkUtilsAPI?.getPopupPages()
		siteConfigBootstrapData.platformServicesAPIData.link.multilingual = multilingualLinkUtilsAPI?.getMultilingualInfo()

		platformRunnerContext.initPlatformOnSite({
			platformEnvData: getPlatformEnvData(),
			appsUrlData: siteConfigBootstrapData.appsUrlData,
			componentSdksClientUrl: siteConfigBootstrapData.componentSdksClientUrl,
			componentSdksServerUrl: siteConfigBootstrapData.componentSdksServerUrl
		})

		return {
			async appWillLoadPage({ pageId: currentPageId, contextId }) {
				if (disablePlatform || (currentPageId === landingPageId && !isChancePlatformOnLandingPage)) {
					return
				}

				// Getting envData on each navigation so it can depend on currentUrl.
				const platformEnvData = getPlatformEnvData()

				if (!platformEnvData.bi.muteFedops) {
					logger.interactionStarted('platform')
				}

				const handlersPromise = Promise.all([pageProvider(contextId, currentPageId), pageProvider('masterPage')]).then(([page, masterPage]) => ({
					masterPageHandlers: getHandlers(masterPage),
					pageHandlers: getHandlers(page)
				}))

				const bootstrapData = createBootstrapData({
					platformEnvData,
					platformBootstrapData: siteConfigBootstrapData,
					viewerModel,
					currentContextId: contextId,
					currentPageId
				})

				const viewerAPI: ViewerAPI = {
					updateProps: (partialProps: PropsMap) => {
						platformRunnerContext.updateProps(partialProps)
					},
					updateStyles: (overrideStyles: { [compId: string]: object }) => {
						const styles = _(overrideStyles)
							.mapValues((compStyles, compId) => ({ ...componentsStylesOverrides.getCompStyle(compId), ...compStyles }))
							.value()
						platformRunnerContext.updateStyles(styles)
					},
					invokeSdkHandler: async (pageId: string, functionName: string, ...args: any) => {
						const { masterPageHandlers, pageHandlers } = await handlersPromise
						const handlers = pageId === 'masterPage' ? masterPageHandlers : pageHandlers
						if (!_.isFunction(handlers[functionName])) {
							logger.captureError(new Error('handler does not exist in page'), {
								tags: { platform: true, handler: functionName },
								extra: { pageId, contextId },
								level: 'info'
							})
							return
						}
						return handlers[functionName](...args)
					}
				}

				if (debugApi) {
					debugApi.platform.logBootstrapMessage(contextId, bootstrapData)
				}

				await platformRunnerContext.runPlatformOnPage(bootstrapData, viewerAPI)
				if (!platformEnvData.bi.muteFedops) {
					logger.interactionEnded('platform')
				}
			}
		}
	}
)
