import _ from 'lodash'
import { PlatformBootstrapData, PlatformEnvData, ViewerModel } from '@wix/thunderbolt-symbols'
import { BootstrapData } from '../types'

export function createBootstrapData({
	platformBootstrapData,
	viewerModel,
	currentContextId,
	currentPageId,
	platformEnvData
}: {
	platformBootstrapData: PlatformBootstrapData
	viewerModel: ViewerModel
	currentContextId: string
	currentPageId: string
	platformEnvData: PlatformEnvData
}): BootstrapData {
	return {
		currentPageId,
		currentContextId,
		platformEnvData,
		sdkFactoriesSiteFeatureConfigs: _.pickBy(viewerModel.siteFeaturesConfigs, (__, featureName) => featureName.toLowerCase().includes('wixcodesdk')),
		...platformBootstrapData
	}
}
